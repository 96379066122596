import type { ResultItem, ResultItem as ResultItemType } from '@/domain';
import { thousandSeparated } from 'shared/util/formatting';
import { safeParseISO } from 'shared/util/datetime-fns';

interface DaysHoursOrMinutes {
    days: number;
    hours: number;
    minutes: number;
}

export const formatTimeUntilDeadline = (timestamp?: string): string => {
    if (timestamp === undefined) return '0 minutt';
    const timeLeft = timeUntilDeadline(timestamp);
    if (timeLeft.hours > 48) {
        return `${timeLeft.days} dager`;
    }
    if (timeLeft.hours > 1) {
        return `${timeLeft.hours} timer`;
    }
    if (timeLeft.hours === 1) {
        return `${timeLeft.hours} time`;
    }
    if (timeLeft.minutes > 1) {
        return `${timeLeft.minutes} minutter`;
    }
    return `${timeLeft.minutes} minutt`;
};

const timeUntilDeadline = (timestamp: string): DaysHoursOrMinutes => {
    const now = new Date();
    const targetDate = safeParseISO(timestamp) ?? new Date();
    const diffInMilliseconds = targetDate.getTime() - now.getTime();
    const diffInMinutes = diffInMilliseconds / (1000 * 60);
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
    return {
        hours: Math.max(0, Math.floor(diffInHours)), // Return 0 if the timestamp is in the past
        days: Math.max(0, Math.floor(diffInDays)),
        minutes: Math.max(0, Math.floor(diffInMinutes)),
    };
};

export const resultItemLocationString = (item: ResultItem) =>
    [item.country, item.county, item.municipality, item.locality].filter((loc) => loc).join(', ');

export const resultItemPriceString = (resultItem: ResultItemType) => {
    if (resultItem.price) {
        if (resultItem.price_per_night) {
            return (
                <>
                    <span className="font-bold">{thousandSeparated(resultItem.price)} kr </span>
                    <span>({thousandSeparated(resultItem.price_per_night)} kr pr. natt)</span>
                </>
            );
        }
        return <p className="font-bold my-8">{thousandSeparated(resultItem.price)} kr </p>;
    }
    if (resultItem.price_min) {
        return <p className="font-bold my-8">Fra {thousandSeparated(resultItem.price_min)} kr pr. natt</p>;
    }
    return <p className="font-bold my-8">Se pris på annonsen</p>;
};

// date format: 11 mar. 2024
export const formatBookingDate = (startDate: Date) => {
    const dateFormatter = new Intl.DateTimeFormat('no', { month: 'short' });
    const month = dateFormatter.format(new Date(startDate));
    const noPeriodMonths = ['mai'];

    const formattedMonth = noPeriodMonths.includes(month.toLowerCase()) ? month : `${month}.`;

    return `${new Date(startDate).getDate()} ${formattedMonth} ${new Date(startDate).getFullYear()}`;
};

// Function to format the date for the dateTime attribute
export const formatDateTime = (dateString: Date) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
};
