import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';

import { replacePropsWithPreview } from '../../utils';

const {
    publicRuntimeConfig: { favouriteBaseUri },
} = getConfig();

export const FavoriteButton = ({ adId, loginId, isMobile, isPreview }) => {
    const [favourite, setFavourite] = useState(undefined);

    useEffect(() => {
        if (!isPreview) {
            const setupFavouriteComponent = async () => {
                const createFavouriteComponent = await FavoriteButton.importFavoriteComponent();
                const FavoriteComponent = createFavouriteComponent({
                    userId: loginId,
                    baseUri: favouriteBaseUri,
                });

                // Cannot update state if something went wrong with creating the favourite component
                if (FavoriteComponent) {
                    setFavourite(
                        <FavoriteComponent
                            itemId={adId}
                            isMobile={isMobile}
                            variant="button"
                            itemType="Ad"
                            title="Lagre i Favoritter"
                            aria-label="Lagre i Favoritter"
                        />,
                    );
                }
            };
            setupFavouriteComponent().catch((error) => {
                console.error('Problems with favourite component', error);
            });
        }
    }, [loginId, adId, isMobile]);

    return typeof favourite === 'undefined' || isPreview ? (
        <button
            className="button button-secondary button--has-icon"
            aria-label="Hjertemerke"
            aria-haspopup="dialog"
            aria-pressed="false"
            {...replacePropsWithPreview({}, isPreview)}
            data-testid="heartButton">
            <span className="icon App-module_iconHeartBlueNeutral__JafxO" />
            <span>Legg til favoritt</span>
        </button>
    ) : (
        <span data-testid="heartDiv">{favourite}</span>
    );
};

// Need this to be able to mock out the dynamic import
FavoriteButton.importFavoriteComponent = async () => (await import('@finn-no/favorite-heart-component')).default;

FavoriteButton.propTypes = {
    adId: PropTypes.number.isRequired,
    loginId: PropTypes.number,
    isMobile: PropTypes.bool,
    isPreview: PropTypes.bool,
};
