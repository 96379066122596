export const scrollToElementByRef = (ref) => {
    if (ref?.current) {
        const headerOffset = 50;
        const elementPosition = ref.current.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });
    }
};

export const focusElementByRef = (ref) => {
    if (ref?.current) {
        ref.current.focus({ preventScroll: true });
    }
};
