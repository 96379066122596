const profiled = [
    'Aircondition',
    'Internettilgang',
    'Balkong/terrasse',
    'Garasje/parkeringsplass',
    'Innendørs svømmebasseng',
    'Utendørs svømmebasseng',
    'Basseng',
    'Kjøkken',
];

export type InfoListProps = {
    list: string[] | null;
    split?: boolean;
};

export const InfoList = ({ list, split }: InfoListProps) => {
    const listItems = (items: string[]) => (items.length ? items.map((item) => <li key={item}>{item}</li>) : null);

    const splitFacilities = (items: string[]) => (
        <>
            {listItems(items.filter((item) => profiled.indexOf(item) >= 0))}
            {listItems(items.filter((item) => profiled.indexOf(item) < 0))}
        </>
    );

    return list && list.length > 0 ? (
        <ul className="list grid grid-cols-2 md:grid-cols-3 gap-8" data-testid="adInfoList">
            {split ? splitFacilities(list) : listItems(list)}
        </ul>
    ) : null;
};
