import getConfig from 'next/config.js';
import { Breadcrumbs } from '@fabric-ds/react';

const {
    publicRuntimeConfig: { contextPath },
} = getConfig();

type FhhBreadcrumbsProps = {
    className?: string;
    isPreview?: boolean;
};

export const FhhBreadcrumbs = ({ className, isPreview }: FhhBreadcrumbsProps) => {
    const previewOnClick = (e) => {
        e.preventDefault();
        alert('Knapper og lenker er skrudd av under forhåndsvisning');
    };

    return (
        <Breadcrumbs className={`mb-16 ${className}`}>
            <a href={isPreview ? '#' : '/reise/'} onClick={isPreview ? () => previewOnClick : undefined} data-testid="vertical">
                Reise
            </a>
            <a
                href={isPreview ? '#' : `${contextPath}/resultat/`}
                onClick={isPreview ? () => previewOnClick : undefined}
                data-testid="submarket">
                Feriehjem <span className="hidden sm:inline">og hytter til leie</span>
            </a>
        </Breadcrumbs>
    );
};
