export const EstateSizeIcon = () => (
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" role="img">
        <title>hus med målestokk</title>
        <g stroke="#767676" strokeWidth="1.95" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <path d="M15.125 22.925v3.9a1.95 1.95 0 001.95 1.95h7.8a1.95 1.95 0 001.95-1.95v-3.9M30.725 20.975l-8.446-7.605a1.95 1.95 0 00-2.608 0l-8.446 7.605M28.775 19.22v-5.07" />
            <path d="M30.725 2.45a.975.975 0 00-.975-.975H2.45a.975.975 0 00-.975.975v27.3c0 .538.437.975.975.975h3.9a.975.975 0 00.975-.975V7.325H29.75a.975.975 0 00.975-.975v-3.9zM5.375 15.125h1.95M5.375 22.925h1.95M15.125 7.325v-1.95M22.925 7.325v-1.95" />
            <path d="M1.475 7.325h5.85v-5.85" />
        </g>
    </svg>
);

export const PropertyTypeIcon = () => (
    <svg width="33" height="32" xmlns="http://www.w3.org/2000/svg" role="img">
        <title>hus</title>
        <g stroke="#767676" strokeWidth="1.95" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <path d="M5.88 18.3v10.725h7.8v-7.8a1.95 1.95 0 011.95-1.95h1.95a1.95 1.95 0 011.95 1.95v7.8h7.8V18.3M1.98 16.35L15.227 3.104a1.95 1.95 0 012.758 0L31.23 16.35" />
            <path d="M22.455 7.575v-1.95h4.875v6.825" />
        </g>
    </svg>
);

export const LocationIcon = () => (
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" role="img">
        <title>posisjonsmarkør</title>
        <g
            transform="translate(0 -.5)"
            stroke="#767676"
            strokeWidth="1.95"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round">
            <circle cx="16.3" cy="10.75" r="3.9" />
            <path d="M16.3 1.975a8.775 8.775 0 018.775 8.775c0 4.226-6.672 13.685-8.38 16.038a.49.49 0 01-.79 0c-1.708-2.353-8.38-11.812-8.38-16.038A8.775 8.775 0 0116.3 1.975z" />
            <path d="M24.073 24.12c3.551.703 5.877 1.876 5.877 3.205 0 2.154-6.11 3.9-13.65 3.9s-13.65-1.746-13.65-3.9c0-1.326 2.315-2.497 5.85-3.202" />
        </g>
    </svg>
);

export const BedroomsIcon = () => (
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" role="img">
        <title>dør åpenet på gløtt</title>
        <g stroke="#767676" strokeWidth="1.95" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <path d="M12.23 15.618a.488.488 0 10.491.482.486.486 0 00-.487-.488h0M20.875 4.4h5.85a1.95 1.95 0 011.95 1.95v19.5a1.95 1.95 0 01-1.95 1.95h-5.85" />
            <path d="M15.862 30.72l-11.7-1.672a.974.974 0 01-.837-.965V5.343c0-.46.321-.858.77-.955l11.7-2.887a.975.975 0 011.18.954v27.3a.975.975 0 01-1.113.965z" />
        </g>
    </svg>
);

export const BedIcon = () => (
    <svg width="33" height="33" xmlns="http://www.w3.org/2000/svg" role="img">
        <title>seng</title>
        <g stroke="#767676" strokeWidth="1.95" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <rect x="5.975" y="1.975" width="21.45" height="29.25" rx="1.95" />
            <rect x="11.825" y="5.875" width="9.75" height="5.85" rx="1.95" />
            <path d="M5.975 11.725h21.45M5.975 17.575h21.45" />
        </g>
    </svg>
);

export const BathroomIcon = () => (
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" role="img">
        <title>dusjhode som spyler vann</title>
        <g stroke="#767676" strokeWidth="1.95" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <path d="M12.588 9.275a7.8 7.8 0 0115.6 0v21.45" />
            <path d="M6.737 15.125a5.85 5.85 0 0111.7 0h-11.7zM7.713 19.025l-.976 2.925M4.787 27.8l-.974 2.925M17.462 19.025l.976 2.925M20.387 27.8l.976 2.925M12.588 19.025v2.925M12.588 30.725V27.8" />
        </g>
    </svg>
);
