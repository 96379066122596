import { EstateSizeIcon, PropertyTypeIcon, LocationIcon, BedroomsIcon, BedIcon, BathroomIcon } from './InfoIcons';
import { InfoEntry } from './InfoEntry';

type DataEntry = { name: string; displayName: string; icon: () => JSX.Element; value: string | null };

export type InfoProps = {
    estateSize: string | null;
    noOfBedrooms: string | null;
    noOfBeds: string | null;
    noOfBathrooms: string | null;
    propertyType: string | null;
    situation: string | null;
};

export const Info = ({ estateSize, noOfBedrooms, noOfBeds, noOfBathrooms, propertyType, situation }: InfoProps) => {
    const entryData: DataEntry[] = [
        { name: 'estateSize', displayName: 'Størrelse', icon: EstateSizeIcon, value: estateSize },
        { name: 'noOfBedrooms', displayName: 'Soverom', icon: BedroomsIcon, value: noOfBedrooms },
        { name: 'noOfBeds', displayName: 'Sengeplasser', icon: BedIcon, value: noOfBeds },
        { name: 'noOfBathrooms', displayName: 'Bad', icon: BathroomIcon, value: noOfBathrooms },
        { name: 'propertyType', displayName: 'Boligtype', icon: PropertyTypeIcon, value: propertyType },
        { name: 'situation', displayName: 'Beliggenhet', icon: LocationIcon, value: situation },
    ];

    const entries = entryData
        .filter((entry): entry is DataEntry & { value: string } => Boolean(entry.value))
        .map(({ name, displayName, icon, value }) => (
            <InfoEntry key={`list-${name}`} description={displayName} value={value} icon={icon} data-testid={`adInfo-${name}`} />
        ));

    return (
        <div className="grid grid-cols-2 md:grid-cols-3 gap-16" data-testid="adInfo">
            {entries}
        </div>
    );
};
