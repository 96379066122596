import { trackClick } from '@/lib/tracking/tracking';
import { replacePropsWithPreview } from '../../utils';

export type DigitalContractProps = {
    adId: number;
    isPreview?: boolean;
};

export const DigitalContract = ({ adId, isPreview = false }: DigitalContractProps) => {
    const digitalContractUrl = `https://swiftcourt.com/no/finn-vacation-rental?finn_id=${adId}`;

    // Pulse tracker id: FHH_ev01
    const pulseData = () => ({
        type: 'Click',
        intent: 'Create',
        name: 'Create digital contract',
        object: {
            id: 'create_digital_contract',
            type: 'UIElement',
            elementType: 'Button',
            hasExtendedProfile: false,
        },
        target: {
            id: digitalContractUrl,
            type: 'ExternalContent',
            name: 'Go to webpage',
            referralCommission: false,
            provider: 'Swiftcourt',
        },
        vertical: {
            name: 'travel',
            subVertical: 'holiday_rental',
        },
    });

    return (
        <>
            <ul className="list list-checked mb-16">
                <li>Utfylt kontrakt med opplysninger fra annonsen</li>
                <li>Signer kontrakten elektronisk</li>
                <li>Gratis tjeneste</li>
            </ul>
            <a
                className="button button--secondary w-full max-w-full"
                rel="noopener noreferrer nofollow external"
                target="_blank"
                {...replacePropsWithPreview(
                    {
                        href: digitalContractUrl,
                        onClick: () => trackClick(pulseData()),
                    },
                    isPreview,
                )}
                data-testid="digitalContract">
                Opprett digital leiekontrakt
            </a>
        </>
    );
};
