import { setInitialBannerFilterValues } from 'shared/banners/bannerParams';
import type { ObjectLocation } from '@/domain';

export const publishBannerEvent = (location?: Partial<ObjectLocation>) => {
    let city: string | undefined = '';
    let country: string | undefined = '';

    if (location) {
        city = location?.locality || location?.address?.split(',')?.slice(-2)[0].trim() || undefined;
        country = location?.country || location?.address?.split(',')?.slice(-1)[0].trim() || undefined;
    }

    setInitialBannerFilterValues({
        pageType: 'object',
        destination: {
            country,
            city,
        },
    });
};
