import { type FormEvent, useEffect, useState } from 'react';
import getConfig from 'next/config';
import cn from 'classnames';
import { Alert, type AlertProps, TextArea } from '@fabric-ds/react';

import { formatISODate } from 'shared/util/datetime-fns';
import { trackSendMessage } from '@/lib/tracking/tracking';
import { useObject } from '@/hooks/useObject';
import { replacePropsWithPreview } from '@/scenes/objectPage/utils';
import { usePreview } from '@/hooks/usePreview';

import { CalendarSelectGuests } from './CalendarSelectGuests';
import { PriceOverview } from '../priceOverview/PriceOverview';

export type CalendarContactFormNotTjvrProps = {
    startDate: Date | null;
    endDate: Date | null;
    closeForm: () => void;
    isLoggedIn: boolean;
};

const {
    publicRuntimeConfig: { apiExternalUrl },
} = getConfig();

const disclaimer = 'FINN.no forbeholder seg retten til å kontrollere og stoppe meldinger.';

export const CalendarContactFormNotTjvr = ({ startDate, endDate, isLoggedIn, closeForm }: CalendarContactFormNotTjvrProps) => {
    const hasDates = startDate != null && endDate != null;

    const isPreview = usePreview();
    const objectData = useObject();
    const { adId, user } = objectData;

    const [numberOfGuests, setNumberOfGuests] = useState('1');
    const [price, setPrice] = useState('');
    const [contactMessage, setContactMessage] = useState('');
    const [pristine, setPristine] = useState(true);
    const [openForm, setOpenForm] = useState(false);

    useEffect(() => {
        if (!hasDates) {
            setPrice('');
        }
    }, [hasDates]);

    const showForm = openForm || (hasDates && isLoggedIn);
    const handleClose = () => {
        setOpenForm(false);
        closeForm();
        setNumberOfGuests('1');
    };

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState<{
        type: AlertProps['type'];
        message: string;
    } | null>(null);

    // tracking plan FHH_ev06
    const trackContactMessageSent = (objectName: string) => {
        trackSendMessage({
            objectName,
            objectData: objectData,
            fromDate: startDate ? formatISODate(startDate) : null,
            toDate: endDate ? formatISODate(endDate) : null,
        });
    };

    const sendContactMessage = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setPristine(false);

        if (contactMessage.length === 0 || !hasDates) {
            return;
        }

        const formData = new FormData();
        formData.append('dateFrom', formatISODate(startDate));
        formData.append('dateTo', formatISODate(endDate));
        formData.append('numberOfGuests', numberOfGuests.toString());
        formData.append('priceTotal', price);
        formData.append('message', contactMessage);

        setStatus(null);
        setLoading(true);

        const trackMessageWithAndWithoutPrice = () =>
            price !== '' ? trackContactMessageSent('with calendar and price') : trackContactMessageSent('with calendar');

        fetch(`${apiExternalUrl}/communication/message/${adId}`, {
            method: 'POST',
            body: formData,
            credentials: 'include',
        })
            .then((res) => (res.ok ? Promise.resolve() : Promise.reject(`${res.status} ${res.statusText}`)))
            .then(() => {
                trackMessageWithAndWithoutPrice();
                setContactMessage('');
                setPristine(true);
                setStatus({
                    type: 'positive',
                    message: 'Melding sendt!',
                });
            })
            .catch(() => {
                const message = user?.isOwner
                    ? 'Det er ikke mulig å sende melding til seg selv.'
                    : 'Ooops... Det skjedde en feil i sendingen, vennligst prøv igjen!';
                setStatus({
                    type: 'negative',
                    message: message,
                });
            })
            .finally(() => setLoading(false));
    };

    const contactMessageIsValid = pristine || contactMessage.length > 0;
    const contactMessageHelpText = contactMessageIsValid ? null : 'Oops, meldingsfeltet må fylles ut.';

    return (
        <>
            {!hasDates && !pristine ? (
                <Alert type="warning" show>
                    Vennligst velg dato i kalenderen.
                </Alert>
            ) : null}

            {(showForm || hasDates) && (
                <CalendarSelectGuests
                    numberOfBeds={objectData.noOfBeds}
                    onChange={(event) => setNumberOfGuests(event.target.value)}
                    numberOfGuests={numberOfGuests}
                    noDefaultValue={false}
                />
            )}
            {hasDates && (
                <PriceOverview
                    adId={adId}
                    startDate={startDate}
                    endDate={endDate}
                    numberOfGuests={Number(numberOfGuests)}
                    setPrice={setPrice}
                    location={objectData.location}
                />
            )}

            {!isLoggedIn ? (
                <a className="button button--primary my-16 w-full max-w-full" data-testid="loginLink" href="/auth/login">
                    Logg inn for å sende melding
                </a>
            ) : !showForm ? (
                <button
                    className="button button--primary my-16 w-full max-w-full"
                    data-testid="showContactForm"
                    {...replacePropsWithPreview(
                        {
                            onClick: (e) => {
                                e.preventDefault();
                                setOpenForm(true);
                            },
                        },
                        isPreview,
                    )}>
                    Skriv en melding til utleier
                </button>
            ) : (
                <form id="contact_form" data-testid="contactForm" onSubmit={sendContactMessage}>
                    <div className="mt-16 text-left space-y-16">
                        <TextArea
                            label="Melding til utleier"
                            rows={5}
                            id="message"
                            value={contactMessage}
                            onChange={(event) => setContactMessage(event.target.value)}
                            data-testid="contactMessage"
                            invalid={!contactMessageIsValid}
                            helpText={contactMessageHelpText}
                        />
                        <div className="input">
                            <button className={cn('button button--primary mb-16', { 'button--in-progress': loading })} type="submit">
                                Send melding
                            </button>
                            <button className="button button--tertiary mb-16 ml-16" type="button" onClick={handleClose}>
                                {status ? 'Lukk' : 'Avbryt'}
                            </button>
                            {status && (
                                <Alert type={status.type} show className="mb-16">
                                    {status.message}
                                </Alert>
                            )}
                            <div className="text-14 mb-16">{disclaimer}</div>
                        </div>
                    </div>
                </form>
            )}
        </>
    );
};
