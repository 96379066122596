import { useState } from 'react';
import { Button } from '@fabric-ds/react';

interface Props {
    intro: JSX.Element;
    children: JSX.Element;
}

export const ExpandableDescription = ({ intro, children }: Props) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <>
            <div className="mb-24">
                {intro}
                {expanded && children}
            </div>
            <Button onClick={() => setExpanded(!expanded)}>{expanded ? '- Vis mindre' : ' + Vis mer'}</Button>
        </>
    );
};
