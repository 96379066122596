import type { CalendarEvent, DateItem } from '@/components/calendar/Calendar';
import { getDayIndexFromWeekdayName, isDateEqual } from './utils';
import { EventTypes, type AvailabilityRules } from '@/domain';

type UseCalendarHandleDateSelectProps = {
    availabilityInfo?: AvailabilityRules;
    events: CalendarEvent[];
    onSelectChange: (dates: { startDate: Date | null; endDate: Date | null }) => void;
    onDaySelect: (dateItem: DateItem) => void;
    selectedEndDate: Date | null;
    selectedDates?: {
        to: Date | null;
        from: Date | null;
    };
    selectedStartDate: Date | null;
    today: Date;
};

const checkIsInvalidCheckinDay = (date: Date, availabilityInfo?: AvailabilityRules): boolean => {
    if (
        availabilityInfo?.availabilityList?.some((day) => day.checkinDay === false && getDayIndexFromWeekdayName(day.day) === date.getDay())
    ) {
        return true;
    }

    return false;
};

export const useCalendarHandleDateSelect = ({
    availabilityInfo,
    events,
    onSelectChange,
    onDaySelect,
    selectedEndDate,
    selectedStartDate,
    today,
}: UseCalendarHandleDateSelectProps) => {
    const handleDaySelect = (dateItem: DateItem) => {
        const date = dateItem.date;

        onDaySelect(dateItem);

        const isInvalidCheckinDay = dateItem.activeEvents?.some((event) => event.type === EventTypes.INVALID_CHECKIN_DAY);
        if (!selectedStartDate && isInvalidCheckinDay) {
            return;
        }

        // Don't allow selection of dates in the past
        if (date < today) {
            return;
        }

        // Don't do anything if a booking is clicked
        const isBooking = dateItem.activeEvents?.some(
            (event) =>
                event.type === EventTypes.BOOKING || event.type === EventTypes.BOOKING_SELECT || event.type === EventTypes.BOOKING_BLOCKED,
        );
        if (isBooking) {
            return;
        }

        // Set startDate if it doesn't exist
        if (selectedStartDate === null) {
            onSelectChange({ startDate: date, endDate: null });
            return;
        }

        // Remove the selection if the startDate or endDate is selected again
        if (dateItem.date.getTime() === selectedStartDate?.getTime() || dateItem.date.getTime() === selectedEndDate?.getTime()) {
            onSelectChange({ startDate: null, endDate: null });
            return;
        }

        // Don't select a range that contains booking events
        const hasBookingInSelection = events?.some((event) => {
            if (!selectedStartDate || !date) {
                return false;
            }

            const [from, to] = [selectedStartDate, date].sort((a, b) => a.getTime() - b.getTime());

            return (
                event.type === (EventTypes.BOOKING || EventTypes.BOOKING_SELECT || EventTypes.BOOKING_BLOCKED) &&
                event.startDate >= from &&
                event.endDate <= to
            );
        });
        if (hasBookingInSelection) {
            // Don't set a new startDate if the day is an invalidCheckinDay
            if (checkIsInvalidCheckinDay(date, availabilityInfo)) {
                onSelectChange({ startDate: null, endDate: null });
                return;
            }

            onSelectChange({ startDate: date, endDate: null });
            return;
        }

        // If the clicked date is the same as the current start date, reset it
        if (isDateEqual(date, selectedStartDate)) {
            onSelectChange({ startDate: null, endDate: null });
            return;
        }

        // If an "endDate" is selected which is earlier than a "startDate", reset "startDate" to newly selected date
        if (selectedStartDate && !selectedEndDate && date < selectedStartDate) {
            if (isInvalidCheckinDay) {
                return;
            }

            onSelectChange({ startDate: date, endDate: null });
            return;
        }

        // Select an "endDate" if a "startDate" has already been selected and it is before "endDate"
        if (selectedStartDate && !selectedEndDate && date > selectedStartDate) {
            const minimumRentalDuration = availabilityInfo?.minRentalDuration ?? undefined;
            const diffTime = Math.abs(selectedStartDate.getTime() - date.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if (minimumRentalDuration && diffDays < minimumRentalDuration) {
                return;
            }

            onSelectChange({ startDate: selectedStartDate, endDate: date });
            return;
        }

        // Select a new "startDate" if a selection date range already exists
        if (selectedStartDate && selectedEndDate) {
            if (checkIsInvalidCheckinDay(date, availabilityInfo)) {
                onSelectChange({ startDate: null, endDate: null });
                return;
            }

            onSelectChange({ startDate: date, endDate: null });
            return;
        }
    };

    return { handleDaySelect };
};
