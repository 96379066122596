// Implementation mostly copied from mfinn's trackAd.js and eventcollectorservice.js
const sendBeacon = (url) => {
    const imageNode = new Image();
    imageNode.setAttribute('src', url.toString());
};

const addTrackingData = (url, adId, counterId) => {
    // Weird double encoded query is by "design". Bulk tracker sends multiple query encoded entries
    url.searchParams.append('e', `&ecType=AD&finnkode=${adId}&ecCategory=${counterId}`);
};

export const trackAdView = ({ adId }) => {
    if (!adId || Number.isNaN(Number(adId))) {
        console.error('adId should only contain numbers');
        return;
    }

    const currentUrl = new URL(document.baseURI);
    const trackingUrl = new URL(currentUrl.origin);
    trackingUrl.pathname += 'ec/b';
    trackingUrl.searchParams.set('client.application', 'travel-app');
    trackingUrl.searchParams.set('referer', document.referrer);

    if (!currentUrl.searchParams.get('s')) {
        addTrackingData(trackingUrl, adId, 'NoOfPageViews_Main');
    }
    if (currentUrl.searchParams.get('ecid')) {
        addTrackingData(trackingUrl, adId, currentUrl.searchParams.get('ecid'));
    }

    sendBeacon(trackingUrl);
};
