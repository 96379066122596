import { useState } from 'react';
import getConfig from 'next/config';

import { trackCheckPriceAndAvailability } from '@/lib/tracking/tracking';
import { formatISODate } from 'shared/util/datetime-fns';
import type { ObjectData } from '@/domain';

const {
    publicRuntimeConfig: { contextPath },
} = getConfig();

interface ImportAdButtonProps {
    checkInDate: Date | null;
    checkOutDate: Date | null;
    objectData: ObjectData;
}

export const ImportAdButton = ({ objectData, checkInDate, checkOutDate }: ImportAdButtonProps) => {
    const isoCheckInDate = checkInDate && formatISODate(checkInDate);
    const isoCheckOutDate = checkOutDate && formatISODate(checkOutDate);
    const deepLink = `${contextPath}/ut/?finnkode=${objectData.adId}&checkInDate=${isoCheckInDate}&checkOutDate=${isoCheckOutDate}`;

    const [submittedOnMissingDates, setSubmittedOnMissingDates] = useState(false);
    const missingDates = checkInDate == null || checkOutDate == null;

    return (
        <>
            <a
                className={'button button--primary my-16 w-full max-w-full'}
                onClick={(event) => {
                    if (missingDates) {
                        event.preventDefault();
                        setSubmittedOnMissingDates(true);
                    } else {
                        trackCheckPriceAndAvailability({
                            ...objectData,
                            owner: {
                                ...objectData.owner,
                                deeplink: deepLink,
                            },
                        });
                    }
                }}
                href={deepLink}
                target="_blank"
                rel="noopener noreferrer nofollow external"
                data-testid="importAdButton">
                Se pris og tilgjengelighet
            </a>
            {missingDates && submittedOnMissingDates && (
                <div className="text-12 text-center text-red-600">Velg datoene du ønsker å leie før du går videre</div>
            )}
        </>
    );
};
