import { useState } from 'react';
import { useSelector } from 'react-redux';

import { isDirectBookingForBrandEnabled } from '@/lib/helpers/importBrands';
import { useObject } from '@/hooks/useObject';
import { usePreview } from '@/hooks/usePreview';
import { generateDayProps, resetHours } from '../../utils';
import type { FhhState, UnleashToggles } from '@/domain';
import { EventTypes } from '@/domain';

import { AvailabilityForImport } from '../../components/availability/AvailabilityForImport';
import type { CalendarEvent } from '@/components/calendar/utils/dates';
import { CalendarContactForm } from './CalendarContactForm';
import { CalendarContactFormNotTjvr } from './CalendarContactFormNotTjvr';
import { CompactCalendar } from '@/components/compactCalendar/CompactCalendar';
import { useAvailabilityForPrivate } from '@/hooks/useAvailabilityForPrivate';
import { Alert } from '@fabric-ds/react';
import { ImportAdButton } from '../../components/importAdButton/ImportAdButton';
import { useAvailabilityPerDate } from '@/hooks/useAvailabilityPerDate';

export interface Props {
    isLoggedIn: boolean;
    selectedDates: { from: Date | null; to: Date | null };
    unleash: Partial<UnleashToggles>;
    norwegianAddress: boolean;
    today?: Date;
}

export function Calendar({ isLoggedIn, selectedDates: initialSelectedDates, unleash, norwegianAddress, today = new Date() }: Props) {
    const objectData = useObject();
    const { adId, bookings, isAdmin, isImport, owner, organisation, bookingEnabled, user } = objectData;
    const { data: availabilityForPrivate } = useAvailabilityForPrivate({ adId, isImmutable: true });
    const availabilityPerDate = useAvailabilityPerDate();
    if (!adId || !bookingEnabled) {
        return null;
    }

    const isPreview = usePreview();

    resetHours(today);
    resetHours(initialSelectedDates?.from);
    resetHours(initialSelectedDates?.to);

    const filters = useSelector((state: FhhState) => state.results.filters);
    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
        (filters?.booking_from ? new Date(filters.booking_from) : initialSelectedDates?.from) ?? null,
    );
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(
        (filters?.booking_to ? new Date(filters.booking_to) : initialSelectedDates?.to) ?? null,
    );
    const selectedDates = { from: selectedStartDate, to: selectedEndDate };

    const calendarEvents: CalendarEvent[] =
        bookings?.map((booking) => ({
            id: String(booking.bookingId),
            type: EventTypes.BOOKING,
            startDate: new Date(booking.startDate),
            endDate: new Date(booking.endDate),
        })) ?? [];

    const handleSelectChange = ({ startDate, endDate }: { startDate: Date | null; endDate: Date | null }) => {
        if (isPreview) {
            return;
        }

        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
    };

    const handleDateReset = () => {
        setSelectedStartDate(null);
        setSelectedEndDate(null);
    };

    return (
        <section data-testid="adCalendar" aria-labelledby="objectCalendarHeading">
            <CompactCalendar
                availabilityInfo={availabilityForPrivate}
                availabilityPerDate={availabilityPerDate}
                calendarDayPropMiddleware={generateDayProps}
                events={calendarEvents}
                onReset={handleDateReset}
                onSelectChange={handleSelectChange}
                selectedDates={selectedDates}
                today={today}
                title="Velg tid i kalenderen"
            />
            <Alert type="info" show={norwegianAddress && user?.isOwner}>
                Det er foreløpig ikke mulig for gjester å velge innsjekk nærmere enn tre dager frem i tid
            </Alert>
            {isDirectBookingForBrandEnabled(String(organisation?.importId), unleash) ? (
                <AvailabilityForImport
                    adId={adId}
                    noAvailableDates={availabilityPerDate?.length === 0}
                    startDate={selectedStartDate}
                    endDate={selectedEndDate}
                />
            ) : isImport ? (
                <ImportAdButton objectData={objectData} checkInDate={selectedStartDate} checkOutDate={selectedEndDate} />
            ) : norwegianAddress ? (
                <CalendarContactForm endDate={selectedEndDate} isLoggedIn={isLoggedIn} startDate={selectedStartDate} />
            ) : (
                <CalendarContactFormNotTjvr
                    closeForm={handleDateReset}
                    endDate={selectedEndDate}
                    isLoggedIn={isLoggedIn}
                    startDate={selectedStartDate}
                />
            )}
        </section>
    );
}
