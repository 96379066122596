import type { ObjectStatus } from '@/domain';

export type StatusProps = { status: ObjectStatus };

export const Status = ({ status }: StatusProps) => {
    const statusText = () => {
        if (status.isDeleted) {
            return 'Slettet';
        }
        return 'Inaktiv';
    };

    const statusClass = status.isDeleted ? 'bg-red-200' : 'bg-yellow-100';

    return status.isActive ? null : (
        <p data-testid="adStatus">
            <span className={`px-8 py-4 rounded-4 ${statusClass}`} data-testid="objectStatus">
                {statusText().toUpperCase()}
            </span>
        </p>
    );
};
