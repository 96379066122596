import type { ObjectUser } from '@/domain';

export type AdministrationProps = {
    adId: number;
    isPreview?: boolean;
    user?: ObjectUser;
};

export const Administration = ({ adId, isPreview, user }: AdministrationProps) => {
    const isOwner = Boolean(user?.isOwner);
    const name = user?.name || null;
    const greeting = name ? `Hei, ${name}!` : 'Hei!';

    if (!isOwner || isPreview) {
        return null;
    }

    return (
        <div className="mx-0 sm:mx-16 mb-16">
            <a
                href={`/user/ads/admin.html?finnkode=${adId}`}
                className="link link--block"
                tabIndex={0}
                aria-label="Endre annonsen og se statistikk"
                data-testid="adOwnerLink">
                <div className="flex gap-16">
                    <div>
                        <img
                            alt="Statistikk-ikon"
                            src="https://static.finncdn.no/_c/mfinn/statistics-icon-27e6411db7df4441323f51ee25b143802cf852547ce19cd659d234b30e5eb685.svg"
                            width="75"
                            height="75"
                        />
                    </div>
                    <div className="px-16 truncate">
                        <h3 className="truncate mb-2 text-gray-700">{greeting}</h3>
                        <p className="truncate mb-2 text-gray-700">Dette er din annonse.</p>
                        <p className="truncate mb-2">Endre annonsen og se statistikk</p>
                    </div>
                </div>
            </a>
        </div>
    );
};
