type PricingEntryProps = {
    description: string;
    value: string;
    additionalInfo?: string;
};

export const PricingEntry = ({ description, value, additionalInfo }: PricingEntryProps) => (
    <div>
        <div className="text-16 font-bold">{description}</div>
        <div className="text-22 font-bold">{value}</div>
        {additionalInfo ? <div className="text-12 text-gray-500">{additionalInfo}</div> : null}
    </div>
);
