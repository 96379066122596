import { useState } from 'react';
import type { Money } from 'shared/models/money';
import { formatMoney } from 'shared/util/formatting';

interface PriceDetailProps {
    title: string;
    money: Money;
    extraPriceInformation?: {
        description: string;
        link: {
            text: string;
            url: string;
        };
    };
}

export const PriceDetail = ({ title, money, extraPriceInformation }: PriceDetailProps) => {
    const [showExtraPriceInformation, setShowExtraPriceInformation] = useState(false);

    const handleTitleClick = () => {
        setShowExtraPriceInformation(!showExtraPriceInformation);
    };

    return (
        <div>
            {title === 'Totalpris' && <hr className="divider" />}
            <p className={`grid grid-cols-3 gap-16 my-8 ${title === 'Totalpris' && 'font-bold'}`}>
                {extraPriceInformation ? (
                    <button className="col-span-2 m-0 underline cursor-pointer text-left" type="button" onClick={handleTitleClick}>
                        {title}
                    </button>
                ) : (
                    <span className="col-span-2 m-0 text-left">{title}</span>
                )}
                <data value={money.value} className="text-right">
                    {formatMoney(money, 0)}
                </data>
            </p>
            {showExtraPriceInformation && (
                <p className="text-left text-12 text-gray-500 pb-10">
                    {extraPriceInformation?.description}
                    {extraPriceInformation?.link && (
                        <a href={extraPriceInformation.link.url} target="_blank" rel="noreferrer">
                            {extraPriceInformation.link.text}
                        </a>
                    )}
                </p>
            )}
        </div>
    );
};
