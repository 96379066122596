import type { ObjectHouseRules } from '@/domain';

type HouseRulesProps = {
    houseRules: ObjectHouseRules;
};

export const HouseRules = ({ houseRules }: HouseRulesProps) => (
    <ul className="list grid grid-cols-2 md:grid-cols-3 gap-8" data-testid="houseRulesList">
        {houseRules.wheelchairAccessible ? (
            <li className="font-bold" key="houseRuleWheelchair">
                Rullestolvennlig
            </li>
        ) : (
            <li key="houseRuleNoWheelchair">Ikke rullestolvennlig</li>
        )}
        {houseRules.smokingAllowed ? (
            <li key="houseRulesSmoking" className="font-bold">
                Røyking tillatt
            </li>
        ) : (
            <li key="houseRulesNoSmoking">Røyking ikke tillatt</li>
        )}
        {houseRules.petsAllowed ? (
            <li key="houseRulesPets" className="font-bold">
                Kjæledyr tillatt
            </li>
        ) : (
            <li key="houseRulesNoPets">Kjæledyr ikke tillatt</li>
        )}
    </ul>
);
