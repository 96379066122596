import useSWRImmutable from 'swr/immutable';
import type { CancellationPolicy } from '@/models/cancellationPolicy';

export const DATA_KEY = 'currentCancellationPolicyKey';

export const useCurrentCancellationPolicy = (): CancellationPolicy | undefined => {
    const { data } = useSWRImmutable<CancellationPolicy>(DATA_KEY);

    return data;
};
