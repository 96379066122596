import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import getConfig from 'next/config';
import { type AdImage, ImageGallery } from '@finn-no/object-page-image-gallery-component';
import '@finn-no/object-page-image-gallery-component/styles.css';

import { trackClick, trackObjectPageView } from '@/lib/tracking/tracking';
import { trackAdView } from '@/lib/tracking/objectTracking';
import { fetchObjectRecommendations, populateObjectPriceInfo } from '@/ducks/object/objectActions';
import { FhhBreadcrumbs } from '@/components/breadcrumbs/Breadcrumbs';
import { publishBannerEvent } from '@/lib/helpers/banner';
import { useObject } from '@/hooks/useObject';
import { usePreview } from '@/hooks/usePreview';
import { useHostBadges } from '@/hooks/useHostBadges';
import type { DeviceType, FhhState, UnleashToggles } from '@/domain';

import { FavoriteButton } from './components/favoriteButton/FavoriteButton';
import { ShareButton } from './components/shareButton/ShareButton';
import { Pricing } from './components/pricing/Pricing';
import { Calendar } from './containers/calendar/Calendar';
import { Info } from './components/info/Info';
import { InfoList } from './components/infoList/InfoList';
import { Profile } from './components/profile/Profile';
import { LocationMap } from './components/locationMap/LocationMap';
import { Footer } from './components/footer/Footer';
import { RecommendationList } from './components/recommendationList/RecommendationList';
import { Links } from './components/links/Links';
import { RatingDesktop } from './components/rating/RatingDesktop';
import { Administration } from './components/administration/Administration';
import { Description } from './components/description/Description';
import { DigitalContract } from './components/digitalContract/DigitalContract';
import { AreaProfile } from './components/areaProfile/AreaProfile';
import { Panel } from './components/panel/Panel';
import { Status } from './components/status/Status';
import { HouseRules } from './components/houseRules/HouseRules';
import { ExpandableDescription } from './components/description/ExpandableDescription';
import { FloatingPanel } from './containers/floatingPanel/FloatingPanel';
import { Rating } from './components/rating/Rating';
import { useAvailabilityForPrivate } from '~/src/hooks/useAvailabilityForPrivate';
import { usePriceData } from '~/src/hooks/usePriceData';
import { useAvailabilityPerDate } from '@/hooks/useAvailabilityPerDate';
import { useAdditionalCost } from '@/hooks/useAdditionalCosts';
import { useCurrentCancellationPolicy } from '@/hooks/useCurrentCancellationPolicy';
import { CancellationRulesList } from '@/components/cancellationPolicy/CancellationRulesList';
import type { CancellationPolicy } from '@/models/cancellationPolicy';

const {
    publicRuntimeConfig: { baseUrl },
} = getConfig();

export interface ObjectPageProps {
    deviceType: DeviceType;
    loginId: number;
    unleash: UnleashToggles;
    validDates: boolean;
}

const parseQueryParam = (val: string | string[]): string => {
    if (Array.isArray(val)) {
        return val.join(',');
    }

    return val;
};

export const ObjectPage = ({ deviceType, loginId, unleash, validDates }: ObjectPageProps) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const finnCode = router.query.finnkode;
    const objectData = useObject();
    const isPreview = usePreview();
    const hostBadges = useHostBadges();
    const profileIdentity = useSelector((state: FhhState) => state.profileData.identity);
    const norwegianAddress = objectData?.location?.countryCode === 'NO';
    const selectedDates = {
        from: validDates && router.query.fra ? new Date(parseQueryParam(router.query.fra)) : null,
        to: validDates && router.query.til ? new Date(parseQueryParam(router.query.til)) : null,
    };
    const { priceInfo, recommendationData } = useSelector((state: FhhState) => state.objectData);
    const {
        adId,
        areaDescription,
        description,
        facilities,
        houseRules,
        heading,
        images,
        isPrivate,
        location,
        modified,
        moreInfo,
        poiNearBy,
        pricing,
        residenceDescription,
        roomsDescription,
        status,
        user,
    } = objectData;

    let currentCancellationPolicy: CancellationPolicy | undefined = undefined;
    if (isPrivate && norwegianAddress) {
        currentCancellationPolicy = useCurrentCancellationPolicy();
    }

    const [initialBannerEventPublished, setInitialBannerEventPublished] = useState(false);
    const isMobile = Boolean(deviceType.isProbablyMobile);
    const isLoggedIn = loginId !== null;
    const ratingRef = useRef(null);
    const { data: availabilityForPrivate } = useAvailabilityForPrivate({ adId, isImmutable: true });
    const availabilityPerDate = useAvailabilityPerDate();
    const filters = useSelector((state: FhhState) => state.results.filters);
    const { data: priceData } = usePriceData(String(adId));
    const { data: additionalCost } = useAdditionalCost(String(adId));

    // Pulse tracker id: CE_ev20
    // TODO why use Common Elements (CE) and not our own definition?
    const trackHomepageClick = () =>
        trackClick({
            type: 'Click',
            intent: 'Open',
            name: 'Homepage link clicked',
            object: {
                id: 'homepage_link_clicked',
                type: 'UIElement',
                elementType: 'Link',
            },
            target: {
                id: objectData.owner.url,
                type: 'ExternalContent',
                name: 'Go to webpage',
                referralCommission: false,
                provider: objectData.owner.name,
            },
            vertical: {
                name: 'travel',
                subVertical: 'holiday_rental',
            },
        });

    const renderProfileAndCalendar = () => {
        return (
            <div className="space-y-16">
                <Profile
                    adId={adId}
                    hostBadges={hostBadges}
                    isImport={objectData.isImport}
                    isPrivate={isPrivate}
                    onHomepageClick={trackHomepageClick}
                    organisation={objectData.organisation}
                    owner={objectData.owner}
                    profileIdentity={profileIdentity}
                />

                {!isMobile && (
                    <div className="p-16 border rounded-8">
                        <Calendar
                            isLoggedIn={isLoggedIn}
                            selectedDates={selectedDates}
                            unleash={unleash}
                            norwegianAddress={norwegianAddress}
                        />
                    </div>
                )}
                {!isPreview && !isMobile && isPrivate && <RatingDesktop adId={adId} ratingRef={ratingRef} />}
                {!norwegianAddress && isPrivate && (
                    <Panel title="Trenger du en kontrakt?" info bleed>
                        <DigitalContract adId={adId} isPreview={isPreview} />
                    </Panel>
                )}
                <Links links={moreInfo} />
            </div>
        );
    };

    const convertImages = () =>
        images?.map((image): AdImage => ({ uri: image.src, width: image.width, height: image.height, description: image.description }));

    const renderPricingFromNewOrOldModel = () => (
        <Pricing
            additionalCost={additionalCost}
            defaultPriceDay={priceData?.defaultPriceDay || null}
            pricing={priceInfo ? { ...priceInfo, year: pricing?.year } : pricing}
            isPrivate={isPrivate}
        />
    );

    const ignoredFacilities: ReadonlyArray<string> = ['Røyking tillatt', 'Rullestolvennlig', 'Kjæledyr tillatt'];
    const facilitiesWithoutHouseRules = facilities ? facilities.filter((facility) => !ignoredFacilities.includes(facility)) : null;

    useEffect(() => {
        if (isPreview || !adId) {
            return;
        }

        trackObjectPageView(finnCode, objectData?.isImport ? objectData.owner?.name : undefined);
        trackAdView({ adId: adId || finnCode });
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }, [finnCode, adId, isPreview, objectData?.isImport, objectData?.owner?.name]);

    useEffect(() => {
        if (isPreview || !adId || initialBannerEventPublished) {
            return;
        }

        publishBannerEvent();
        setInitialBannerEventPublished(true);
    }, [adId, initialBannerEventPublished, isPreview]);

    useEffect(() => {
        if (isPreview || !adId) {
            return;
        }

        dispatch(populateObjectPriceInfo(adId));
        dispatch(fetchObjectRecommendations(adId));
    }, [adId, isPreview, dispatch]);

    return (
        <div className="mb-24" data-testid="objectPage">
            <FhhBreadcrumbs className="mx-0 sm:mx-16 pt-8" isPreview={isPreview} />
            <Administration adId={adId} isPreview={isPreview} user={user} />
            <ImageGallery images={convertImages()} adId={adId.toString()} data-testid="adImageContainer" />
            <div className="grid md:grid-cols-3 gap-16 pb-32 mt-16">
                <article className="md:col-span-2 space-y-24">
                    <div className="mx-0 sm:mx-16 flex space-x-8 sm:space-x-16 items-center">
                        <FavoriteButton adId={adId} loginId={loginId} isMobile={isMobile} isPreview={isPreview} />
                        {adId && heading && <ShareButton adId={adId} heading={heading} isEnabled={!isPreview} />}
                    </div>
                    <div className="mx-0 sm:mx-16 space-y-32">
                        {status && <Status status={status} />}
                        <h1 data-testid="adHeading" className="break-words">
                            {heading}
                        </h1>
                        {renderPricingFromNewOrOldModel()}
                        <Info
                            estateSize={objectData.estateSize}
                            noOfBedrooms={objectData.noOfBedrooms}
                            noOfBeds={objectData.noOfBeds}
                            noOfBathrooms={objectData.noOfBathrooms}
                            propertyType={objectData.propertyType}
                            situation={objectData.situation}
                        />
                    </div>
                    {norwegianAddress && isPrivate && (
                        <Panel title="Slik fungerer det for deg som leier" data-testid="tenant-info-title">
                            <ExpandableDescription
                                intro={
                                    <p className="mb-24">
                                        FINN tilbyr en utleieplattform som skal gjøre det så enkelt og trygt som mulig å leie feriehjem og
                                        hytter. Dette innebærer:
                                    </p>
                                }>
                                <>
                                    <h4>Verifiserte utleiere</h4>
                                    <p className="mb-24">Alle utleiere med utleieobjekter i Norge er verifisert med BankID av oss.</p>
                                    {unleash?.tjvrMultipleCancellationPolicies ? (
                                        <>
                                            <h4>Sikker betaling</h4>
                                            <p className="mb-24">
                                                Pengene holdes trygt hos vår betalingspartner til 24 timer etter innsjekksdato.
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <h4>Trygg betaling</h4>
                                            <p className="mb-24">
                                                Du betaler en del av beløpet når du reserverer, og resterende beløp senest 30 dager før
                                                innsjekk. Pengene oppbevares hos vår betalingspartner Adyen, som overfører pengene videre
                                                til utleier 24 timer etter innsjekkingsdatoen.
                                            </p>
                                        </>
                                    )}

                                    {unleash?.tjvrMultipleCancellationPolicies ? (
                                        <>
                                            <h4>Fleksibel avbestilling</h4>
                                            <p className="mb-24">
                                                Du kan avbestille når som helst, med mulighet for delvis eller full refusjon avhengig av
                                                hvor tett opp mot innsjekk du kansellerer.
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <h4>Kanselleringsregler</h4>
                                            <p className="mb-24">
                                                Ved avbestilling senest 28 dager før innsjekkingsdatoen, får du pengene dine tilbake,
                                                bortsett fra et gebyr knyttet til kostnaden for selve betalingstransaksjonene. Ved
                                                kansellering 28-15 dager før innsjekkingsdatoen refunderes du 50 % av innbetalt beløp. Du
                                                vil ikke refunderes for avbestillinger 14 dager eller mindre før innsjekkingsdatoen.
                                            </p>
                                        </>
                                    )}
                                </>
                            </ExpandableDescription>
                        </Panel>
                    )}
                    {residenceDescription && (
                        <Panel title="Om boligen" data-testid="residence-title">
                            <Description description={residenceDescription} data-testid="residence-description" />
                        </Panel>
                    )}
                    {!residenceDescription && description && (
                        <Panel title="Beskrivelse" data-testid="description-title">
                            <Description description={description} data-testid="description" />
                        </Panel>
                    )}
                    {roomsDescription && (
                        <Panel title="Beskrivelse av rom" data-testid="rooms-title">
                            <Description description={roomsDescription} data-testid="room-description" />
                        </Panel>
                    )}

                    <Panel title="Fasiliteter">
                        <InfoList list={facilitiesWithoutHouseRules} split />
                    </Panel>
                    {houseRules && (
                        <Panel title="Husregler og tilgjengelighet" data-testid="houserules">
                            <HouseRules houseRules={houseRules} />
                        </Panel>
                    )}
                    {unleash?.tjvrMultipleCancellationPolicies && norwegianAddress && isPrivate ? (
                        <Panel title="Avbestilling" data-testid="cancellation">
                            <CancellationRulesList policy={currentCancellationPolicy} />
                        </Panel>
                    ) : null}
                    <Panel title="I nærheten">
                        <InfoList list={poiNearBy} />
                    </Panel>
                    <Panel title="Beliggenhet" data-testid="area-title">
                        {areaDescription && <Description description={areaDescription} data-testid="area-description" />}
                        <LocationMap {...location} />
                    </Panel>
                    {isMobile && <div data-testid="objectPage-mobileProfileAndCalendar">{renderProfileAndCalendar()}</div>}
                    {norwegianAddress && isPrivate && <AreaProfile adId={adId} />}
                    <Footer adId={adId} heading={heading} isPreview={isPreview} modified={modified} />
                    {!isPreview && isPrivate && (
                        <Panel title="Vurderinger fra gjester" ref={ratingRef}>
                            <Rating adId={adId} isMobile={isMobile} />
                        </Panel>
                    )}
                </article>
                {!isMobile && <div data-testid="objectPage-profileAndCalendar">{renderProfileAndCalendar()}</div>}
            </div>

            {recommendationData?.items && recommendationData.items.length > 0 && (
                <Panel title="Andre feriehjem og hytter" data-testid="objectPage-recommendations">
                    <RecommendationList items={recommendationData.items} />
                </Panel>
            )}

            {isMobile && (
                <FloatingPanel
                    initialSelectedDates={selectedDates}
                    isLoggedIn={isLoggedIn}
                    unleash={unleash}
                    norwegianAddress={norwegianAddress}
                    objectData={objectData}
                    availabilityForPrivate={availabilityForPrivate}
                    availabilityPerDate={availabilityPerDate}
                    isPreview={isPreview}
                    filters={filters}
                />
            )}
        </div>
    );
};
