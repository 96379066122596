import useSWRImmutable from 'swr/immutable';

export const DATA_KEY = 'hostBadgesKey';

export type HostBadgesData = {
    highResponseRate?: boolean;
};

export const useHostBadges = (): HostBadgesData => {
    const { data } = useSWRImmutable<HostBadgesData>(DATA_KEY);

    return data || {};
};
