import type { CalendarEvent } from '@/components/calendar/Calendar';
import type { AvailabilityPerDate } from '@/domain';
import { EventTypes } from '@/domain';
import {
    createMinRentalDurationEventsPerDate,
    getExpiredEventsWithSelectEvent,
    getInvalidCheckinDayEventListPerDate,
    moveBookingEventEndDateBackOneDay,
} from '@/hooks/utils';

// This is the editted version of the events we receive so it should be sorted
const bookingEventsWithoutSelectEvent = ({
    availabilityPerDate,
    bookingEvents,
    activeMonthDate,
    today,
}: {
    availabilityPerDate?: AvailabilityPerDate[];
    bookingEvents: CalendarEvent[];
    activeMonthDate: Date;
    today: Date;
}): CalendarEvent[] => {
    const invalidCheckinDayEventList = getInvalidCheckinDayEventListPerDate({
        bookingEvents,
        availabilityPerDate,
        activeMonthDate,
        today,
    });
    // This is used so users can select the last day of a booking
    const updatedEvents = bookingEvents.map((event) => moveBookingEventEndDateBackOneDay(event)).concat(invalidCheckinDayEventList);

    return updatedEvents;
};

const bookingEventsWithSelectEvent = ({
    availabilityPerDate,
    bookingEvents,
    selectedStartDate,
    selectedEndDate,
    activeMonthDate,
}: {
    availabilityPerDate?: AvailabilityPerDate[];
    bookingEvents: CalendarEvent[];
    selectedStartDate: Date;
    selectedEndDate: Date | null;
    activeMonthDate: Date;
}): CalendarEvent[] => {
    const updatedEvents = bookingEvents.map((event) => moveBookingEventEndDateBackOneDay(event));
    const selectEvent: CalendarEvent = {
        id: 'select',
        type: EventTypes.SELECT,
        startDate: selectedStartDate,
        endDate: selectedEndDate ?? selectedStartDate,
    };
    const expiredEvents = getExpiredEventsWithSelectEvent({ selectedStartDate, activeMonthDate });
    const minRentalDurationEvents = createMinRentalDurationEventsPerDate({
        availabilityPerDate,
        bookingEvents: updatedEvents,
        selectEvent,
    });

    return [selectEvent, ...updatedEvents, ...expiredEvents, ...minRentalDurationEvents];
};

type UseDatesProps = {
    availabilityPerDate?: AvailabilityPerDate[];
    events: CalendarEvent[];
    activeMonthDate: Date;
    selectedStartDate: Date | null;
    selectedEndDate: Date | null;
    today: Date;
};
export const useCalendarEvents = ({
    availabilityPerDate,
    events,
    activeMonthDate,
    selectedStartDate,
    selectedEndDate,
    today,
}: UseDatesProps) => {
    const bookingEvents = [...events]
        // We shouldn't be dealing with other events being passed in here
        .filter(
            (event) =>
                event.type === EventTypes.BOOKING || event.type === EventTypes.BOOKING_SELECT || event.type === EventTypes.BOOKING_BLOCKED,
        )
        // Sort by startDate. We don't support overlapping booking events
        .sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
        // Clone the array's objects too to make sure we don't mutate the originals
        .map((event) => ({ ...event }));
    const updatedEvents: CalendarEvent[] = selectedStartDate
        ? bookingEventsWithSelectEvent({
              // checkout
              bookingEvents,
              selectedStartDate,
              selectedEndDate,
              availabilityPerDate,
              activeMonthDate,
          })
        : bookingEventsWithoutSelectEvent({
              //checkin
              availabilityPerDate,
              bookingEvents,
              activeMonthDate,
              today,
          });

    return { updatedEvents };
};
