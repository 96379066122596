import getConfig from 'next/config';
import { Card, Clickable } from '@fabric-ds/react';

import styles from './RecommendationItem.module.css';
import type { ObjectRecommendationItem } from '@/domain';

const {
    publicRuntimeConfig: { contextPath },
} = getConfig();

const makeSrcSet = (path: string) =>
    path?.replace ? `${path.replace('/default/', '/640w/')} 640w, ` + `${path.replace('/default/', '/320x240c/')} 320w` : path;
const applyImageRule = (url: string, rule: string) => (url?.replace ? url.replace('/default/', rule) : url);

export const RecommendationItem = ({ image, version, score, itemId, heading, label }: ObjectRecommendationItem) => {
    const selectItem = () => {
        if (sessionStorage) {
            sessionStorage.setItem('searchParams', window.location.search);
        }
    };

    const renderImage = () =>
        image?.url ? (
            <img
                className="h-144 w-full object-cover"
                src={applyImageRule(image.url, '/480w/')}
                srcSet={makeSrcSet(image.url)}
                alt="Annonsebilde"
                aria-hidden="true"
                data-testid="adImage"
                sizes="(min-width: 990px) 300px, (min-width: 768px) 33vw, (min-width: 480px) 50vw"
            />
        ) : (
            <img
                src={'https://static.finncdn.no/_c/mfinn/static/images/no_image_universal.4e750c62.png'}
                className="image-frame img-format__img"
                alt="Bilde mangler"
                width="320"
                height="213"
                aria-hidden="true"
                data-testid="recImage"
            />
        );

    return (
        <Card className={styles.recommendationItem} key={`recItem${itemId}`} data-version={version} data-score={score}>
            <div aria-owns={`recTitle${itemId}`} />
            {renderImage()}
            <div className="p-16 relative">
                <h3 className="truncate text-16 font-normal text-gray-800" data-testid="recTitle" id={`recTitle${itemId}`}>
                    <Clickable
                        href={`${contextPath}/ad.html?finnkode=${itemId}`}
                        target="_blank"
                        title={heading}
                        className="text-current hover:no-underline focus:no-underline"
                        onClick={selectItem}>
                        {heading}
                    </Clickable>
                </h3>
                <p className="font-bold my-8">{label}</p>
            </div>
        </Card>
    );
};
