import { PricingEntry } from './PricingEntry';
import { createMoney, getDisplayCurrency } from 'shared/models/money';
import type { AdditionalCost, ObjectPricing, PriceData } from '@/domain';
import { getPricePerNight } from '@/utils/price';
import { DEFAULT_CURRENCY } from '@/constants';

export interface PricingProps {
    additionalCost?: AdditionalCost;
    pricing: ObjectPricing;
    isPrivate: boolean;
    defaultPriceDay: PriceData['defaultPriceDay'];
}

export const Pricing = ({ additionalCost, defaultPriceDay, pricing, isPrivate }: PricingProps) => {
    const currency = pricing.currency || DEFAULT_CURRENCY;
    const pricePerNightFrom = defaultPriceDay ? defaultPriceDay : getPricePerNight(pricing);
    const minRequiredNight = pricing?.minimumRentalDuration ?? 1;

    return (
        <div className="grid md:grid-cols-2 gap-16" data-testid="adPricing">
            <div className="grid gap-16" data-testid="entry-col-1">
                {pricePerNightFrom && (
                    <PricingEntry
                        description={'Pris per natt'}
                        value={`fra ${getDisplayCurrency(createMoney({ value: pricePerNightFrom, currency }))}`}
                        additionalInfo={'Prisen er oppgitt uten rabatter og ekstra kostnader'}
                    />
                )}
                {minRequiredNight > 1 && <PricingEntry description={'Minste opphold'} value={`${minRequiredNight} netter`} />}
            </div>
            <div className="grid gap-16" data-testid="entry-col-2">
                {isPrivate
                    ? additionalCost && (
                          <PricingEntry description={'Rengjøring'} value={getCleaningValue(additionalCost, pricing?.currency || 'NOK')} />
                      )
                    : pricing.cleaning && (
                          <PricingEntry
                              description={'Rengjøring'}
                              value={getDisplayCurrency(createMoney({ value: pricing.cleaning, currency }))}
                          />
                      )}
            </div>
        </div>
    );
};

const getCleaningValue = (additionalCost: AdditionalCost, currency: string): string => {
    if (additionalCost?.priceCleaningType === 'FEE' && additionalCost?.priceCleaning) {
        const money = createMoney({ value: additionalCost.priceCleaning, currency: currency || DEFAULT_CURRENCY });
        return getDisplayCurrency(money);
    }
    if (additionalCost?.priceCleaningType === 'GUEST_CLEANS') {
        return 'Leietaker vasker';
    }
    return 'Inkludert i leieprisen';
};
