import { useEffect, useRef, useState } from 'react';
import getConfig from 'next/config';
import { trust } from '@finn-no/trust-web-sdk-finn';

import { ErrorBoundary } from 'shared/util/ErrorBoundary';

const {
    publicRuntimeConfig: { trustFeedbackApiUrl },
} = getConfig();

type RatingProps = {
    adId: number;
    isMobile?: boolean;
};

export const Rating = ({ adId, isMobile }: RatingProps) => {
    const [trustFailed, setTrustFailed] = useState(false);
    const reputationWidget = useRef(null);
    const listingWidget = useRef(null);

    useEffect(() => {
        const settings = {
            itemSDRN: `sdrn:finn:classified:${adId}`,
            apiUrl: trustFeedbackApiUrl,
            locale: 'nb-NO',
            onError: (e) => {
                if (!e.toString().includes('Response status: 404')) {
                    setTrustFailed(true);
                    console.error(`Problems with showing ratings for ${adId}`, e);
                }
            },
        };

        if (reputationWidget.current && listingWidget.current) {
            if (isMobile) {
                trust.itemReputation.run(reputationWidget.current, settings);
            }
            // Can use `newInstance: true` for the first render too as the behavior is the same
            trust.publicFeedbackListing.run(listingWidget.current, { ...settings, newInstance: true });
        }
    }, [trustFailed, adId]);

    return (
        <ErrorBoundary>
            {trustFailed ? (
                <div>Vi klarte ikke å hente vurderinger nå, prøv igjen senere</div>
            ) : (
                <>
                    <div ref={reputationWidget} data-trust-item-reputation-widget="placeholder" />
                    <div ref={listingWidget} data-trust-feedback-item-listing-widget="placeholder" className="mt-16" />
                </>
            )}
        </ErrorBoundary>
    );
};
