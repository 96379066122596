import cn from 'classnames';

import { safeParseISO } from 'shared/util/datetime-fns';

import { ProfileFallbackAvatar } from '../profileFallbackAvatar/ProfileFallbackAvatar';
import type { ProfileIdentity } from '@/domain';

import styles from './ProfilePublic.module.css';
import { Badges } from '../badges/Badges';
import type { HostBadgesData } from '@/hooks/useHostBadges';

type ProfileAvatarProps = Partial<Pick<ProfileIdentity, 'avatar' | 'name'>> & { title: string };

const ProfileAvatar = ({ avatar, name, title }: ProfileAvatarProps) => (
    <div className={styles.avatar}>
        {avatar ? (
            <img className={styles.avatarImg} src={avatar} alt={name ? `profilbilde for ${name}` : 'profilbilde'} />
        ) : (
            <ProfileFallbackAvatar label={title} />
        )}
    </div>
);

export interface ProfilePublicProps {
    badges: HostBadgesData;
    identity?: ProfileIdentity;
}

export const ProfilePublic = ({ badges, identity }: ProfilePublicProps) => {
    const { localProfileId, verified, name, memberSince, avatar } = identity ?? {};
    const memberSinceYear = safeParseISO(memberSince)?.getFullYear();

    return (
        <div data-testid="trustProfile">
            <div className={cn(styles.container, 'finn-trust-profile text-left')}>
                <div className={cn(styles.profilePublic, 'center')}>
                    <h2 className={styles.heading}>Profilinformasjon om selgeren</h2>
                    <ProfileAvatar
                        avatar={avatar}
                        name={name}
                        title={
                            localProfileId ? 'Standard avatar når en bruker ikke har lastet opp eget bilde' : 'Bilde av en anonym avatar'
                        }
                    />
                    <div className={styles.container}>
                        <div className={styles.identity}>
                            {identity ? (
                                <a href={`/profile?userId=${localProfileId}`} className={cn(styles.link, styles.profileLink, 'text-16')}>
                                    {name}
                                </a>
                            ) : (
                                <div>FINN-bruker</div>
                            )}
                            {verified ? (
                                <a data-testid="verifiedUser" href="/eid/verification.html" className="z-2">
                                    <svg
                                        viewBox="0 0 18 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="32px"
                                        width="32px"
                                        role="img"
                                        aria-label="Verifisert bruker">
                                        <g fill="none" fillRule="evenodd">
                                            <path fill="#0063FB" d="M3 4.545L9 2l6 2.545C15 9 15 14.091 9 16 3 14.09 3 9 3 4.545z" />
                                            <path
                                                fill="#FFF"
                                                fillRule="nonzero"
                                                d="M6.877 7.417L5.5 8.771 8.276 11.5 12.5 7.354 11.125 6 8.277 8.794z"
                                            />
                                        </g>
                                    </svg>
                                </a>
                            ) : null}
                        </div>
                        {memberSinceYear ? <div className="text-14">Har vært på FINN siden {memberSinceYear}</div> : null}
                        <Badges data={badges} />
                    </div>
                </div>
            </div>
        </div>
    );
};
