import getConfig from 'next/config';
import { safeFormatISODate } from 'shared/util/datetime-fns';
import { queryString } from 'shared/util/query';
import { replacePropsWithPreview } from '../../utils';
import { useState } from 'react';

const {
    publicRuntimeConfig: { contextPath },
} = getConfig();

export interface NextButtonProps {
    adId: number;
    selectedDates: { from: Date | null; to: Date | null };
    numberOfGuests: string;
    isPreview: boolean;
    open: boolean;
    isLoggedIn: boolean;
}

export const NextButton = ({ adId, selectedDates, numberOfGuests, isPreview, open, isLoggedIn }: NextButtonProps) => {
    const [isInProgress, setInProgress] = useState(false);
    const [errors, setErrors] = useState<{ dates: boolean; guests: boolean }>({ dates: false, guests: false });

    const noGuestsSelected = numberOfGuests === 'Velg antall';
    const hasDates = selectedDates.from !== null && selectedDates.to !== null;
    const showPrice = hasDates && !noGuestsSelected;
    const showLogInButton = !isLoggedIn && showPrice;
    const bookingUrl = `${contextPath}/opprettforesporsel/${queryString({
        finnkode: adId,
        startDate: safeFormatISODate(selectedDates.from),
        endDate: safeFormatISODate(selectedDates.to),
        guests: numberOfGuests,
    })}`;

    const handleButtonClick = (e: { preventDefault: () => void }) => {
        if (!hasDates) {
            e.preventDefault();
            setErrors({ ...errors, dates: true });
        } else if (noGuestsSelected) {
            e.preventDefault();
            setErrors({ ...errors, guests: true });
        } else {
            setInProgress(true);
            // Workaround for sticky progress bar when command-clicking the link:
            setTimeout(() => {
                setInProgress(false);
            }, 3000);
        }
    };

    const previewProps = replacePropsWithPreview({ onClick: handleButtonClick }, isPreview);

    const RenderHelpText = ({ hasDates, noGuestsSelected, errors, showPrice }) => {
        switch (true) {
            case !hasDates && errors.dates:
                return <p className="text-12 text-center text-red-600 my-8">Velg datoene du ønsker å leie før du går videre</p>;
            case hasDates && noGuestsSelected && errors.guests:
                return <p className="text-12 text-center text-red-600 my-8">Velg antall gjester før du går videre</p>;
            case showPrice:
                return <p className="text-12 text-center text-gray-500 mt-8">Du betaler ingenting ennå</p>;
            default:
                return null;
        }
    };

    return (
        <div>
            <a
                aria-label={showLogInButton ? 'Logg inn for å gå videre' : 'Gå videre til bestilling'}
                className={`button button--primary w-full max-w-full ${isInProgress ? 'in-progress' : ''}`}
                href={showLogInButton ? `/auth/login?redirectUrl=${bookingUrl}` : bookingUrl}
                {...previewProps}>
                {showLogInButton ? 'Logg inn for å gå videre' : 'Gå videre'}
            </a>
            {open && RenderHelpText({ hasDates, noGuestsSelected, errors, showPrice })}
        </div>
    );
};
