import useSWRImmutable from 'swr/immutable';
import type { AvailabilityPerDate } from '@/domain';

export const DATA_KEY = 'availabilityPerDateKey';

export const useAvailabilityPerDate = (): AvailabilityPerDate[] | undefined => {
    const { data } = useSWRImmutable<AvailabilityPerDate[]>(DATA_KEY);

    return data;
};
