import { useRef, useCallback, useEffect } from 'react';

export const useModalFocusTrap = (open: boolean) => {
    const modalRef = useRef<HTMLDivElement>(null);

    const handleTabKey = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Tab' && modalRef.current) {
            const focusableModalElements = modalRef.current.querySelectorAll(
                'a[href], button, textarea, input, select, [tabindex]:not([tabindex="-1"])',
            );
            const firstElement = focusableModalElements[0] as HTMLElement;
            const lastElement = focusableModalElements[focusableModalElements.length - 1] as HTMLElement;

            if (e.shiftKey) {
                if (document.activeElement === firstElement) {
                    lastElement.focus();
                    e.preventDefault();
                }
            } else {
                if (document.activeElement === lastElement) {
                    firstElement.focus();
                    e.preventDefault();
                }
            }
        }
    }, []);

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
            document.addEventListener('keydown', handleTabKey);

            if (modalRef.current) {
                modalRef.current.focus();
            }
        } else {
            document.body.style.overflow = 'auto';
            document.removeEventListener('keydown', handleTabKey);
        }

        return () => {
            document.body.style.overflow = 'auto';
            document.removeEventListener('keydown', handleTabKey);
        };
    }, [open, handleTabKey]);

    return { modalRef };
};
