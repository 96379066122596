import { type Ref, useEffect, useRef, useState } from 'react';
import getConfig from 'next/config';
import { trust } from '@finn-no/trust-web-sdk-finn';

import { focusElementByRef, scrollToElementByRef } from '@/lib/helpers/scroll';
import { ErrorBoundary } from 'shared/util/ErrorBoundary';

const {
    publicRuntimeConfig: { trustFeedbackApiUrl },
} = getConfig();

type RatingDesktopProps = {
    adId: number;
    ratingRef?: Ref<HTMLElement>;
};

export const RatingDesktop = ({ adId, ratingRef }: RatingDesktopProps) => {
    const [trustFailed, setTrustFailed] = useState(false);

    const reputationWidget = useRef(null);

    const onClick = () => {
        scrollToElementByRef(ratingRef);
        focusElementByRef(ratingRef);
    };

    useEffect(() => {
        const settings = {
            itemSDRN: `sdrn:finn:classified:${adId}`,
            apiUrl: trustFeedbackApiUrl,
            locale: 'nb-NO',
            onError: (e: Error) => {
                setTrustFailed(true);
                if (!e.toString().includes('Response status: 404')) {
                    console.error(`Problems with showing ratings for ${adId}`, e);
                }
            },
        };

        if (reputationWidget.current) {
            trust.itemReputation.run(reputationWidget.current, settings);
        }
    }, [trustFailed, adId]);

    return (
        <ErrorBoundary>
            <div className="objectRatingWidgetDesktop">
                {!trustFailed && (
                    <>
                        <div className="mb-8" ref={reputationWidget} data-trust-item-reputation-widget="placeholder" />
                        <button type="button" className="button button--link" onClick={onClick}>
                            Se alle vurderinger
                        </button>
                    </>
                )}
            </div>
        </ErrorBoundary>
    );
};
