import type { ComponentClass, FunctionComponent } from 'react';

export type InfoEntryProps = {
    description: string;
    value: string;
    icon: ComponentClass | FunctionComponent;
};

export const InfoEntry = ({ description, value, icon: Icon, ...rest }: InfoEntryProps) => (
    <div className="flex gap-16 items-center" {...rest}>
        <div className="">
            <Icon />
        </div>
        <div className="">
            <div data-testid="description">{description}</div>
            <div className="font-bold" data-testid="value">
                {value}
            </div>
        </div>
    </div>
);
