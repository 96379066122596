import { useState } from 'react';
import getConfig from 'next/config';

import { trackClick } from '@/lib/tracking/tracking';

import { IconCheck16, IconFacebook24, IconMail24, IconShare16, IconTwitter24 } from '@fabric-ds/icons/react';
import { Button, Modal } from '@fabric-ds/react';

const {
    publicRuntimeConfig: { baseUrl },
} = getConfig();

export type ShareButtonProps = {
    adId: number;
    heading: string;
    isEnabled: boolean;
};

const shareAdTexts = {
    buttons: {
        shareAd: 'Del',
        copyLink: 'Kopier lenke',
        copyDone: 'Kopiert',
        shareOnFacebook: 'Del på Facebook',
        shareOnTwitter: 'Del på Twitter',
        shareByMail: 'Del via e-post',
    },
    modal: {
        title: 'Del lenke',
    },
};

export const ShareButton = ({ adId, heading, isEnabled = true }: ShareButtonProps) => {
    const [shouldShowModal, setShouldShowModal] = useState(false);
    const [hasCopiedLink, setHasCopiedLink] = useState(false);

    const shareLink = `https://www.finn.no/${adId}`;

    const shareUrls = {
        email: `mailto:?subject=Tips: ${heading}&body=Jeg fant dette på finn.no: ${baseUrl}/${adId}`,
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${baseUrl}/${adId}?taid%3DShare_ad_facebook`,
        twitter:
            `https://twitter.com/intent/tweet?hashtags=FINN_no&related=Finn_no&url=${baseUrl}/${adId}` +
            `?taid%3DShare_ad_twitter&text=${heading}`,
    };

    const toggleModal = () => {
        setShouldShowModal(!shouldShowModal);
    };

    const copyLink = () => {
        const shareLinkTextFieldId = document.getElementById('share-link-text') as HTMLInputElement | null;
        shareLinkTextFieldId?.select();
        navigator?.clipboard?.writeText(shareLinkTextFieldId?.value || '');
        if (!hasCopiedLink) {
            setHasCopiedLink(true);
        }
    };

    const track = (name, type) => {
        trackClick({
            type: 'Click',
            intent: 'Share',
            name: 'Share ad',
            object: {
                id: `share_ad_${name}`,
                type: 'UIElement',
                name: `${type} icon`,
                items: [
                    {
                        type: 'ClassifiedAd',
                        id: adId,
                        contentId: adId.toString(),
                        name: heading,
                        category: 'travel > holiday rental',
                    },
                ],
                hasExtendedProfile: false,
            },
            vertical: {
                name: 'travel',
                subVertical: 'holiday_rental',
            },
        });
    };

    if (!isEnabled) {
        return null;
    }

    return (
        <>
            <button type="button" className="button button--flat flex items-center" data-testid="share-ad-button" onClick={toggleModal}>
                <IconShare16 width="20px" height="20px" /> <span className="hidden sm:block px-8 text-blue-600 whitespace-nowrap">Del</span>
            </button>
            <Modal open={shouldShowModal} right onDismiss={toggleModal} title={shareAdTexts.modal.title}>
                <div data-testid="share-ad-modal">
                    <input
                        onClick={(event) => (event.target as HTMLInputElement).select()}
                        className="w-full"
                        type="text"
                        data-testid="share-link-text"
                        id="share-link-text"
                        value={shareLink}
                        readOnly
                    />
                    <div className="flex justify-between mt-16">
                        <div className="flex">
                            <a
                                href={shareUrls.email}
                                title={shareAdTexts.buttons.shareByMail}
                                onClick={() => track('tip-a-friend', 'Email')}
                                aria-label={shareAdTexts.buttons.shareByMail}
                                data-testid="send-email-link"
                                target="_blank"
                                className="p-12 button button--pill"
                                rel="noopener noreferrer nofollow external">
                                <IconMail24 />
                            </a>
                            <a
                                href={shareUrls.facebook}
                                title={shareAdTexts.buttons.shareOnFacebook}
                                onClick={() => track('share-facebook', 'Facebook')}
                                aria-label={shareAdTexts.buttons.shareOnFacebook}
                                data-testid="facebook-share-link"
                                target="_blank"
                                className="p-12 button button--pill"
                                rel="noopener noreferrer nofollow external">
                                <IconFacebook24 />
                            </a>
                            <a
                                href={shareUrls.twitter}
                                title={shareAdTexts.buttons.shareOnTwitter}
                                onClick={() => track('share-twitter', 'Twitter')}
                                aria-label={shareAdTexts.buttons.shareOnTwitter}
                                data-testid="twitter-share-link"
                                target="_blank"
                                className="p-12 button button--pill"
                                rel="noopener noreferrer nofollow external">
                                <IconTwitter24 />
                            </a>
                        </div>
                        <Button
                            primary
                            className="flex items-center"
                            onClick={copyLink}
                            data-testid="copy-link-button"
                            aria-label={hasCopiedLink ? shareAdTexts.buttons.copyDone : shareAdTexts.buttons.copyLink}>
                            {hasCopiedLink ? (
                                <>
                                    <span className="mr-8">{shareAdTexts.buttons.copyDone}</span>
                                    <IconCheck16 />
                                </>
                            ) : (
                                shareAdTexts.buttons.copyLink
                            )}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
