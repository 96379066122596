import useSWR from 'swr';

import { formatISODate } from 'shared/util/datetime-fns';
import { fetchAvailabilityForImport } from '@/api/api';

type Availability = Record<string, number | boolean | Error> | null;
type DirectBookingPrice = Record<string, unknown> | null;
export type AvailabilityResponse = {
    availability: Availability;
    price: DirectBookingPrice;
};

export const useAvailabilityForImport = (adId: number, startDate: Date | null, endDate: Date | null) => {
    const shouldFetch = !!startDate; // only fetch availability data when a date is selected
    const key = shouldFetch ? { adId, startDate: formatISODate(startDate), endDate: endDate ? formatISODate(endDate) : null } : null;
    const { data, error } = useSWR<AvailabilityResponse, Error>(key, fetchAvailabilityForImport, { revalidateOnFocus: false });

    return {
        availability: error ? { error } : data?.availability,
        price: data?.price,
        loading: shouldFetch && !data && !error,
    };
};
