import dynamic from 'next/dynamic';
import type { MapPOI } from 'shared/map/interfaces';

import styles from './LocationMap.module.css';
import classNames from 'classnames';

export type LocationProps = {
    address?: string;
    locality?: string;
    latitude?: number;
    longitude?: number;
};

const DynamicMap = dynamic(() => import('shared/map/ClientSideMap'), {
    loading: () => <div className="map-placeholder" />,
    ssr: false,
});

export const LocationMap = ({ address, locality, longitude, latitude }: LocationProps) => {
    // TODO REIS-4591 Switch to only address when we switch to fasaden
    const locationText = address && locality && !address.includes(locality) ? `${address}, ${locality}` : address || locality || '';

    const pois: MapPOI[] =
        longitude && latitude
            ? [
                  {
                      name: locationText,
                      coordinates: [longitude, latitude],
                  },
              ]
            : [];

    return (
        <>
            <div className="mb-16" data-testid="locationText">{`${locationText}`}</div>
            {longitude && latitude ? (
                <DynamicMap
                    id="object-page-map"
                    selectedPois={pois}
                    className={classNames(['panel', styles.objectPageMapContainer])}
                    disableScrollZoomUnlessFocused
                    fullscreenControl
                />
            ) : null}
        </>
    );
};
