import cn from 'classnames';

import type { ObjectOrganization } from '@/domain';

import styles from './ProfileCompany.module.css';

type ProfileCompanyProps = {
    ownerMobile?: string;
    ownerPhone?: string;
    ownerName: string;
    organisation?: ObjectOrganization;
};

export const ProfileCompany = ({ ownerName, ownerMobile, ownerPhone, organisation }: ProfileCompanyProps) => {
    const renderPhoneInformation = () => (
        <div>
            <span>{ownerPhone ? 'Telefon: ' : 'Mobil: '}</span>
            <span>
                <a href={`tel:${ownerPhone ? ownerPhone : ownerMobile}`} rel="external">
                    {ownerPhone ? ownerPhone : ownerMobile}
                </a>
            </span>
        </div>
    );

    return (
        <div data-testid="companyProfile">
            {organisation && (organisation.logo?.trim().length ?? 0) > 0 ? (
                <img
                    className={cn(styles.logo, 'img-format__img')}
                    alt={organisation.name ?? ''}
                    src={`https://images.finncdn.no/dynamic/142w/logo/${organisation.logo}`}
                />
            ) : null}
            {/* Do not show the name of the organisation for import ads, just the logo */}
            {organisation?.importId ? null : <div className="mt-4">{ownerName}</div>}
            {ownerPhone || ownerMobile ? renderPhoneInformation() : null}
        </div>
    );
};
