import type { CancellationPolicy } from '@/models/cancellationPolicy';

export const noPayoutRuleHost = (fiftyPercentDeadline: number) =>
    `Leietaker får full refusjon hvis avbestillingen skjer mer enn ${fiftyPercentDeadline} dager før innsjekksdato.`;

export const fiftyPercentPayoutRuleHost = (fiftyPercentDeadline: number, noRefundDeadline: number) =>
    `Leietaker får 50 % refusjon hvis avbestillingen skjer ${noRefundDeadline}–${fiftyPercentDeadline} dager før innsjekksdato.`;

export const fullPayoutRuleHost = (noRefundDeadline: number) =>
    `Leietaker får ingen refusjon hvis avbestillingen skjer mindre enn ${noRefundDeadline} dager før innsjekksdato.`;

export const fullRefundRuleTenant = (fiftyPercentDeadline: number) =>
    `Full refusjon hvis avbestillingen skjer mer enn ${fiftyPercentDeadline} dager før innsjekksdato.`;

export const fiftyPercentRefundRuleTenant = (fiftyPercentDeadline: number, noRefundDeadline: number) =>
    `50 % refusjon hvis avbestillingen skjer ${noRefundDeadline}–${fiftyPercentDeadline} dager før innsjekksdato.`;

export const noRefundRuleTenant = (noRefundDeadline: number) =>
    `Ingen refusjon hvis avbestillingen skjer mindre enn ${noRefundDeadline} dager før innsjekksdato.`;

export const fullRefundRuleHost = (fiftyPercentDeadline: number) =>
    `Leietaker får full refusjon hvis avbestillingen skjer mer enn ${fiftyPercentDeadline} dager før innsjekksdato.`;

export const fiftyPercentRefundRuleHost = (fiftyPercentDeadline: number, noRefundDeadline: number) =>
    `Leietaker får 50 % refusjon hvis avbestillingen skjer ${noRefundDeadline}–${fiftyPercentDeadline} dager før innsjekksdato.`;

export const noRefundRuleHost = (noRefundDeadline: number) =>
    `Leietaker får ingen refusjon hvis avbestillingen skjer mindre enn ${noRefundDeadline} dager før innsjekksdato.`;

export const getTestPolicy = () => {
    return {
        id: 'MODERATE',
        displayName: 'Moderat',
        fiftyPercentRefundDeadlineDays: 30,
        noRefundDeadlineDays: 14,
        paymentDeadlineDays: 30,
    } as CancellationPolicy;
};
