import type { ObjectPricing } from '@/domain';

// NB This logic is duplicated in no/finn/travel/fhh/contentpush/solrindex/SolrIndexer.kt
export const getPricePerNight = (pricing: ObjectPricing): number | null => {
    const priceDay = pricing.day?.from || pricing.day?.to;
    if (priceDay) {
        return priceDay;
    }
    const priceWeek = pricing.week?.from || pricing.week?.to;
    if (priceWeek) {
        return Math.floor(priceWeek / 7);
    }
    const priceWeekend = pricing.weekend?.from || pricing.weekend?.to;
    if (priceWeekend) {
        return Math.floor(priceWeekend / 2);
    }
    return null;
};
