import cn from 'classnames';

import { RecommendationItem } from '../recommendationItem/RecommendationItem';
import styles from './RecommendationList.module.css';
import type { ObjectRecommendationItem } from '@/domain';

interface RecommendationListProps {
    items: ObjectRecommendationItem[];
}
export const RecommendationList = ({ items }: RecommendationListProps) =>
    items && items.length > 0 ? (
        <div className={cn(styles.recommendationList, 'grid grid-cols-2 md:grid-cols-3 gap-16 pb-32')} data-testid="adRecommendations">
            {items.map((item) => (
                <RecommendationItem key={item.itemId} {...item} />
            ))}
        </div>
    ) : null;
