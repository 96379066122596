import type { CancellationPolicy } from '@/models/cancellationPolicy';
import { Alert } from '@fabric-ds/react';
import { fiftyPercentRefundRuleTenant, fullRefundRuleTenant, noRefundRuleTenant } from '@/utils/CancellationPolicyUtils';

type Props = {
    policy: CancellationPolicy | undefined;
};

export const CancellationRulesList = ({ policy }: Props) => {
    if (!policy) {
        return (
            <Alert show type="negative">
                Beklager, noe gikk galt ved henting av kanselleringsregler.
            </Alert>
        );
    }

    return (
        <ul className="list-inside list-disc space-y-8 mt-16">
            <li>{fullRefundRuleTenant(policy.fiftyPercentRefundDeadlineDays)}</li>
            <li>{fiftyPercentRefundRuleTenant(policy.fiftyPercentRefundDeadlineDays, policy.noRefundDeadlineDays)}</li>
            <li>{noRefundRuleTenant(policy.noRefundDeadlineDays)}</li>
        </ul>
    );
};
