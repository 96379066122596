import { replacePropsWithPreview } from '../../utils';
import { trackClick } from '@/lib/tracking/tracking';

export type FooterProps = {
    adId: number;
    heading: string | null;
    modified: string | null;
    isPreview?: boolean;
};

export const Footer = ({ adId, heading, modified, isPreview }: FooterProps) => {
    const handleReportAdClick = () => {
        trackClick({
            type: 'Click',
            intent: 'Report',
            name: 'Report ad',
            object: {
                type: 'UIElement',
                name: 'link',
                items: [
                    {
                        type: 'ClassifiedAd',
                        id: adId,
                        contentId: adId,
                        name: heading,
                        category: 'travel > holiday rental',
                    },
                ],
                hasExtendedProfile: false,
            },
            target: {
                type: 'ClassifiedAd',
                name: 'Report Ad',
                contentId: adId,
            },
            vertical: {
                name: 'travel',
                subVertical: 'holiday_rental',
            },
        });
    };

    return (
        <div className="mx-0 sm:mx-16" data-testid="adFooter">
            <a
                data-testid="reportAd"
                {...replacePropsWithPreview(
                    {
                        href: `/report/ad?adId=${adId}`,
                        rel: 'external',
                        onClick: handleReportAdClick,
                    },
                    Boolean(isPreview),
                )}>
                Rapporter annonse
            </a>
            <table className="mt-8">
                <tbody>
                    <tr>
                        <th className="text-left whitespace-nowrap p-0">FINN-kode</th>
                        <td className="pl-16">
                            <span data-testid="footerAdId">{adId}</span>
                        </td>
                    </tr>
                    {modified && (
                        <tr>
                            <th className="text-left whitespace-nowrap p-0">Sist endret</th>
                            <td className="pl-16" data-testid="lastModified">
                                {modified}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};
