import type { DateItem } from '~/src/components/calendar/utils/dates';
import { EventTypes } from '@/domain';

interface ReplacePropsWithPreviewTypes {
    href?: string;
    onClick?: (MouseEvent?) => void;
    rel?: string;
}

export const replacePropsWithPreview = (normalProps: ReplacePropsWithPreviewTypes, isPreview: boolean): ReplacePropsWithPreviewTypes => {
    if (isPreview) {
        return {
            onClick: (e: MouseEvent) => {
                e.preventDefault();
                alert('Knapper og lenker er skrudd av under forhåndsvisning');
            },
        };
    }
    return normalProps;
};

export const generateDayProps = (dateItem: DateItem): Record<string, string | boolean> => {
    const hasBookingEvent =
        dateItem.activeEvents?.some((event) => event.type === EventTypes.BOOKING || event.type === EventTypes.BOOKING_SELECT) || false;

    return hasBookingEvent ? { disabled: true } : {};
};

export const resetHours = (date: Date | null) => date?.setHours(0, 0, 0, 0);

export const renderCurrency = (price: string) => {
    return price.includes('NOK') ? price.replace('NOK', 'kr') : price;
};

type DateSetter = React.Dispatch<React.SetStateAction<Date | null>>;
type SelectChangeArgs = { startDate: Date | null; endDate: Date | null };

export const handleSelectChange =
    (isPreview: boolean, setSelectedStartDate: DateSetter, setSelectedEndDate: DateSetter) =>
    ({ startDate, endDate }: SelectChangeArgs): void => {
        if (!isPreview) {
            setSelectedStartDate(startDate);
            setSelectedEndDate(endDate);
        }
    };

export const handleDateReset =
    (setSelectedStartDate: DateSetter, setSelectedEndDate: DateSetter): (() => void) =>
    () => {
        setSelectedStartDate(null);
        setSelectedEndDate(null);
    };
