import { useEffect, useState } from 'react';
import getConfig from 'next/config';
import cn from 'classnames';

import FinnWidget from '@eiendomsprofil/finn-widget-web';
import '@eiendomsprofil/finn-widget-web/dist/style.css';

import { ErrorBoundary } from 'shared/util/ErrorBoundary';

import styles from './AreaProfile.module.css';

type AreaProfileProps = {
    adId: number;
};

const {
    publicRuntimeConfig: { apiExternalUrl },
} = getConfig();

export const AreaProfile = ({ adId }: AreaProfileProps) => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => setIsClient(true), []);

    const endpoint = `${apiExternalUrl}/area-profile?id=${adId}`;

    return (
        <div className={cn(styles.areaProfile, 'mx-0 sm:mx-16 nabolag-widget')} data-testid="nabolag-widget">
            {isClient && (
                <ErrorBoundary>
                    <FinnWidget
                        endpoint={endpoint}
                        config={{
                            resizeThrottleMs: 150,
                            scrollThrottleMs: 66,
                        }}
                    />
                </ErrorBoundary>
            )}
        </div>
    );
};
