import { useRef } from 'react';
import { IconClose16 } from '@fabric-ds/icons/react';
import cn from 'classnames';

import styles from './CloseButton.module.css';

interface CloseButtonProps {
    onClick: () => void;
    setOpen: (open: boolean) => void;
    className?: string;
}

export const CloseButton = ({ onClick, setOpen, className }: CloseButtonProps) => {
    const closeButtonRef = useRef<HTMLButtonElement>(null);

    const handleCloseClick = () => {
        onClick();
        setOpen(false);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleCloseClick();
        }
    };

    return (
        <button
            type="button"
            className={cn(styles['close-button'], className)}
            ref={closeButtonRef}
            aria-label="Lukk"
            onClick={handleCloseClick}
            onKeyDown={handleKeyDown}>
            <IconClose16 aria-hidden="true" focusable="false" />
        </button>
    );
};
