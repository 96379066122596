import { forwardRef, type HTMLAttributes } from 'react';

export interface PanelProps extends HTMLAttributes<HTMLDivElement> {
    title: string;
    info?: boolean;
    bleed?: boolean;
}
export const Panel = forwardRef<HTMLHeadingElement, PanelProps>(({ children, title, info, bleed, ...otherProps }, ref) => (
    <div className={`mx-0 ${bleed ? '' : 'sm:mx-16'} ${info ? 'p-16 rounded-8 bg-bluegray-100' : ''}`} {...otherProps}>
        <h2 className="h3" data-testid="title" {...(ref && { ref: ref, tabIndex: -1 })}>
            {title}
        </h2>
        {children}
    </div>
));

Panel.displayName = 'Panel';
