import getConfig from 'next/config';

import { ProfileCompany } from '../profileCompany/ProfileCompany';
import { ProfilePublic } from '../profilePublic/ProfilePublic';
import type { ObjectOrganization, ObjectOwner, ProfileIdentity } from '@/domain';
import type { HostBadgesData } from '@/hooks/useHostBadges';

const {
    publicRuntimeConfig: { contextPath },
} = getConfig();

export type ProfileProps = {
    adId: number;
    hostBadges: HostBadgesData;
    isImport: boolean;
    isPrivate: boolean;
    onHomepageClick: () => void;
    organisation?: ObjectOrganization;
    owner: ObjectOwner;
    profileIdentity?: ProfileIdentity;
};

export const Profile = ({ adId, hostBadges, isImport, isPrivate, onHomepageClick, organisation, owner, profileIdentity }: ProfileProps) => {
    if (!owner) {
        return null;
    }

    // If import ads don't have any data they can use, don't show the profile at all
    if (isImport && !owner.mobile && !owner.phone && !organisation) {
        return null;
    }

    return (
        <div className="p-16 border rounded-8" data-testid="objectPage-profile">
            <div data-testid="adProfile" className="text-center">
                {isImport && (
                    <ProfileCompany
                        ownerName={owner.name}
                        ownerPhone={owner.phone || undefined}
                        ownerMobile={owner.mobile || undefined}
                        organisation={organisation}
                    />
                )}

                {isPrivate && <ProfilePublic identity={profileIdentity} badges={hostBadges} />}
                {!isImport && owner.url && (
                    <a
                        href={`${contextPath}/ut?finnkode=${adId}&deepLinkParams=`}
                        data-testid="webpage"
                        className="block"
                        target="_blank"
                        rel="noopener noreferrer nofollow external"
                        onClick={() => onHomepageClick()}>
                        Hjemmeside
                    </a>
                )}
            </div>
        </div>
    );
};
