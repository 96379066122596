import { useState } from 'react';
import cn from 'classnames';
import { IconMinus16, IconPlus16 } from '@fabric-ds/icons/react';

import styles from './Description.module.css';

interface Props {
    description: string;
}

export const Description = ({ description }: Props) => {
    const longDescription: boolean = description?.length > 671;
    const [expanded, setExpanded] = useState(!longDescription);

    return (
        <div className="overflow-hidden">
            <div className="mb-0 relative">
                <div className="preserve-linebreaks" data-testid="adDescription">
                    <div
                        data-testid="value"
                        className={cn(styles.description, {
                            [styles['description--expanded']]: expanded,
                        })}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                </div>
                <div className={expanded ? '' : 'read-more'}>
                    {longDescription && (
                        <button type="button" className="button button--link mt-8" onClick={() => setExpanded(!expanded)}>
                            {expanded ? <IconMinus16 className="inline-block mr-8" /> : <IconPlus16 className="inline-block mr-8" />}
                            <span className="align-text-top">{expanded ? 'Vis mindre' : 'Vis mer'}</span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
