import { Component, type PropsWithChildren, type ReactNode } from 'react';

type ErrorBoundaryProps = {
    fallback?: ReactNode;
};

type ErrorBoundaryState = {
    hasError: boolean;
};

export class ErrorBoundary extends Component<PropsWithChildren<ErrorBoundaryProps>, ErrorBoundaryState> {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    // TODO REIS-4403: proper error logging should be done here
    componentDidCatch(error, errorInfo) {
        console.error(error);
        console.error(errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback || null;
        }
        return this.props.children;
    }
}

export const withErrorBoundary = (TheComponent) => {
    const Wrapped = (props) => (
        <ErrorBoundary>
            <TheComponent {...props} />
        </ErrorBoundary>
    );
    // Format for display in DevTools
    const name = TheComponent.displayName || TheComponent.name;
    Wrapped.displayName = name ? `WithErrorBoundary(${name})` : 'WithErrorBoundary';

    return Wrapped;
};
